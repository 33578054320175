// TaskList.js

import React, { useState, useEffect } from "react";
import { getTasks, saveTasks } from "./localStorageUtils";

const TaskList = ({ updatePoints, completeReset }) => {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState(""); // Declare newTask state

  // Fetch tasks from backend on mount
  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const storedTasks = await getTasks();
        setTasks(storedTasks);
      } catch (error) {
        console.error("Failed to fetch tasks:", error);
      }
    };

    fetchTasks();
  }, []);

  const toggleTaskCompletion = async (taskId) => {
    const updatedTasks = tasks.map((task) => {
      if (task.id === taskId) {
        const completed = !task.completed;
        // Update points when a task is completed
        if (completed) {
          updatePoints(10); // Assume each task gives 10 points
        } else {
          updatePoints(-10); // Deduct points if unmarked
        }
        return { ...task, completed };
      }
      return task;
    });
    setTasks(updatedTasks);
    await saveTasks(updatedTasks);
  };

  const addTask = async (e) => {
    e.preventDefault();
    if (newTask.trim() === "") return;
    const task = {
      id: Date.now(),
      name: newTask,
      completed: false,
      subtasks: [],
    };
    const updatedTasks = [task, ...tasks];
    setTasks(updatedTasks);
    setNewTask("");
    await saveTasks(updatedTasks);
  };

  const deleteTask = async (id) => {
    const updatedTasks = tasks.filter((task) => task.id !== id);
    setTasks(updatedTasks);
    await saveTasks(updatedTasks);
  };

  return (
    <div className="p-4 bg-gradient-to-r from-[#00e5ff] via-[#86cecb] to-[#137a7f] rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4 text-white">Today's Tasks</h2>
      <form className="mb-4 flex items-center" onSubmit={addTask}>
        <input
          type="text"
          className="p-2 border rounded flex-grow bg-[#373b3e] text-white"
          placeholder="Add a new task"
          value={newTask}
          onChange={(e) => setNewTask(e.target.value)}
        />
        <button
          type="submit"
          className="ml-2 px-4 py-2 bg-[#e12885] text-white rounded hover:bg-pink-600"
        >
          Add Task
        </button>
      </form>
      {tasks.map((task) => (
        <div
          key={task.id}
          className={`p-4 my-4 rounded-lg shadow-lg transform transition-all duration-300 ${
            task.completed
              ? "bg-gradient-to-r from-[#e12885] to-[#00e5ff] text-white"
              : "bg-[#00e5ff] text-gray-800"
          }`}
        >
          <h2 className="text-lg font-semibold">{task.name}</h2>
          <div className="flex justify-between items-center mt-2">
            <button
              className={`px-4 py-2 text-sm font-bold rounded ${
                task.completed
                  ? "bg-white text-[#e12885] hover:bg-gray-200"
                  : "bg-white text-[#137a7f] hover:bg-gray-100"
              }`}
              onClick={() => toggleTaskCompletion(task.id)}
            >
              {task.completed ? "Completed" : "Mark as Complete"}
            </button>
            <button
              className="px-4 py-2 text-sm font-bold bg-red-500 text-white rounded hover:bg-red-600"
              onClick={() => deleteTask(task.id)}
            >
              Delete
            </button>
          </div>
        </div>
      ))}
      <button
        className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
        onClick={completeReset}
      >
        Complete Reset
      </button>
    </div>
  );
};

export default TaskList;
