// localStorageUtils.js

const API_BASE_URL = "https://lifebackend-3o51.onrender.com/api";



// Utility to get the authorization header
const getAuthHeaders = () => {
  const token = localStorage.getItem("authToken");
  if (!token) throw new Error("User is not authenticated");
  return { Authorization: `Basic ${token}` };
};

// Tasks
export const getTasks = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/tasks`, {
      headers: getAuthHeaders(),
    });
    if (!response.ok) throw new Error("Failed to fetch tasks");
    const data = await response.json();
    return data.tasks || [];
  } catch (error) {
    console.error("Error fetching tasks:", error);
    throw error;
  }
};

export const saveTasks = async (tasks) => {
  try {
    const response = await fetch(`${API_BASE_URL}/tasks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeaders(),
      },
      body: JSON.stringify({ tasks }),
    });
    if (!response.ok) throw new Error("Failed to save tasks");
  } catch (error) {
    console.error("Error saving tasks:", error);
    throw error;
  }
};

// Rewards
export const getStoredRewards = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/rewards`, {
      headers: getAuthHeaders(),
    });
    if (!response.ok) throw new Error("Failed to fetch rewards");
    const data = await response.json();
    return data.rewards || [];
  } catch (error) {
    console.error("Error fetching rewards:", error);
    throw error;
  }
};

export const saveRewardsToStorage = async (rewards) => {
  try {
    const response = await fetch(`${API_BASE_URL}/rewards`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeaders(),
      },
      body: JSON.stringify({ rewards }),
    });
    if (!response.ok) throw new Error("Failed to save rewards");
  } catch (error) {
    console.error("Error saving rewards:", error);
    throw error;
  }
};

export const resetRewardsInStorage = async () => {
  try {
    await saveRewardsToStorage([]); // Reset rewards to empty array
  } catch (error) {
    console.error("Error resetting rewards:", error);
    throw error;
  }
};

// Points
export const getPoints = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/points`, {
      headers: getAuthHeaders(),
    });
    if (!response.ok) throw new Error("Failed to fetch points");
    const data = await response.json();
    return data.points || 0;
  } catch (error) {
    console.error("Error fetching points:", error);
    throw error;
  }
};

export const savePoints = async (points) => {
  try {
    const response = await fetch(`${API_BASE_URL}/points`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeaders(),
      },
      body: JSON.stringify({ points }),
    });
    if (!response.ok) throw new Error("Failed to save points");
  } catch (error) {
    console.error("Error saving points:", error);
    throw error;
  }
};
