// rewardsConfig.js
export const defaultRewards = [
  { id: 1, src: "/mai-images/Mai1.jpg", unlocked: false, cost: 30 },
  { id: 2, src: "/mai-images/Mai2.png", unlocked: false, cost: 50 },
  { id: 3, src: "/mai-images/Mai3.png", unlocked: false, cost: 70 },
  { id: 4, src: "/mai-images/Mai4.png", unlocked: false, cost: 90 },
  { id: 5, src: "/mai-images/Mai5.png", unlocked: false, cost: 110 },
  { id: 6, src: "/mai-images/Mai6.png", unlocked: false, cost: 130 },
  { id: 7, src: "/mai-images/Mai7.png", unlocked: false, cost: 150 },
  { id: 8, src: "/mai-images/Mai8.png", unlocked: false, cost: 170 },
  { id: 9, src: "/mai-images/Mai9.png", unlocked: false, cost: 190 },
  { id: 10, src: "/mai-images/Mai10.png", unlocked: false, cost: 210 },
  { id: 11, src: "/mai-images/Mai11.png", unlocked: false, cost: 230 },
  { id: 12, src: "/mai-images/Mai12.png", unlocked: false, cost: 250 },
  { id: 13, src: "/mai-images/Mai13.png", unlocked: false, cost: 270 },
  { id: 14, src: "/mai-images/Mai14.png", unlocked: false, cost: 290 },
  { id: 15, src: "/mai-images/Mai15.png", unlocked: false, cost: 310 },
  { id: 16, src: "/mai-images/Mai16.png", unlocked: false, cost: 330 },
  { id: 17, src: "/mai-images/Mai17.png", unlocked: false, cost: 350 },
  { id: 18, src: "/mai-images/Mai18.png", unlocked: false, cost: 370 },
  { id: 19, src: "/mai-images/Mai19.png", unlocked: false, cost: 390 },
  { id: 20, src: "/mai-images/Mai20.png", unlocked: false, cost: 410 },
  { id: 21, src: "/mai-images/Mai21.png", unlocked: false, cost: 430 },
  { id: 22, src: "/mai-images/Mai22.png", unlocked: false, cost: 450 },
  { id: 23, src: "/mai-images/Mai23.png", unlocked: false, cost: 470 },
  { id: 24, src: "/mai-images/Mai24.png", unlocked: false, cost: 490 },
  { id: 25, src: "/mai-images/Mai25.png", unlocked: false, cost: 510 },
  { id: 26, src: "/mai-images/Mai26.png", unlocked: false, cost: 530 },
  { id: 27, src: "/mai-images/Mai27.png", unlocked: false, cost: 550 },
  { id: 28, src: "/mai-images/Mai28.png", unlocked: false, cost: 570 },
  { id: 29, src: "/mai-images/Mai29.png", unlocked: false, cost: 590 },
  { id: 30, src: "/mai-images/Mai30.png", unlocked: false, cost: 610 },
  { id: 31, src: "/mai-images/Mai31.png", unlocked: false, cost: 630 },
  { id: 32, src: "/mai-images/Mai32.png", unlocked: false, cost: 650 },
  { id: 33, src: "/mai-images/Mai33.png", unlocked: false, cost: 670 },
  { id: 34, src: "/mai-images/Mai34.png", unlocked: false, cost: 690 },
  { id: 35, src: "/mai-images/Mai35.png", unlocked: false, cost: 710 },
  { id: 36, src: "/mai-images/Mai36.png", unlocked: false, cost: 730 },
  { id: 37, src: "/mai-images/Mai37.png", unlocked: false, cost: 750 },
  { id: 38, src: "/mai-images/Mai38.png", unlocked: false, cost: 770 },
  { id: 39, src: "/mai-images/Mai39.png", unlocked: false, cost: 790 },
  { id: 40, src: "/mai-images/Mai40.png", unlocked: false, cost: 810 },
  { id: 41, src: "/mai-images/Mai41.png", unlocked: false, cost: 830 },
  { id: 42, src: "/mai-images/Mai42.png", unlocked: false, cost: 850 },
  { id: 43, src: "/mai-images/Mai43.png", unlocked: false, cost: 870 },
  { id: 44, src: "/mai-images/Mai44.png", unlocked: false, cost: 890 },
  { id: 45, src: "/mai-images/Mai45.png", unlocked: false, cost: 910 },
  { id: 46, src: "/mai-images/Mai46.png", unlocked: false, cost: 930 },
  { id: 47, src: "/mai-images/Mai47.png", unlocked: false, cost: 950 },
  { id: 48, src: "/mai-images/Mai48.png", unlocked: false, cost: 970 },
  { id: 49, src: "/mai-images/Mai49.png", unlocked: false, cost: 990 },
  { id: 50, src: "/mai-images/Mai50.png", unlocked: false, cost: 1010 },
  { id: 51, src: "/mai-images/Mai51.png", unlocked: false, cost: 1030 },
  { id: 52, src: "/mai-images/Mai52.png", unlocked: false, cost: 1050 },
  { id: 53, src: "/mai-images/Mai53.png", unlocked: false, cost: 1070 },
  { id: 54, src: "/mai-images/Mai54.png", unlocked: false, cost: 1090 },
  { id: 55, src: "/mai-images/Mai55.png", unlocked: false, cost: 1110 },
  { id: 56, src: "/mai-images/Mai56.png", unlocked: false, cost: 1130 },
  { id: 57, src: "/mai-images/Mai57.webp", unlocked: false, cost: 1150 },
  { id: 58, src: "/mai-images/Mai58.webp", unlocked: false, cost: 1170 },
  { id: 59, src: "/mai-images/Mai59.webp", unlocked: false, cost: 1190 },
  { id: 60, src: "/mai-images/Mai60.webp", unlocked: false, cost: 1210 },
  { id: 61, src: "/mai-images/Mai61.webp", unlocked: false, cost: 1230 },
  { id: 62, src: "/mai-images/Mai62.webp", unlocked: false, cost: 1250 },
  { id: 63, src: "/mai-images/Mai63.webp", unlocked: false, cost: 1270 },
  { id: 64, src: "/mai-images/Mai64.webp", unlocked: false, cost: 1290 },
  { id: 65, src: "/mai-images/Mai65.webp", unlocked: false, cost: 1310 },
  { id: 66, src: "/mai-images/Mai66.webp", unlocked: false, cost: 1330 },
  { id: 67, src: "/mai-images/Mai67.webp", unlocked: false, cost: 1350 },
  { id: 68, src: "/mai-images/Mai68.webp", unlocked: false, cost: 1370 },
  { id: 69, src: "/mai-images/Mai69.webp", unlocked: false, cost: 1390 },
  { id: 70, src: "/mai-images/Mai70.webp", unlocked: false, cost: 1410 },
  { id: 71, src: "/mai-images/Mai71.webp", unlocked: false, cost: 1430 },
  { id: 72, src: "/mai-images/Mai72.webp", unlocked: false, cost: 1450 },
  { id: 73, src: "/mai-images/Mai73.webp", unlocked: false, cost: 1470 },
  { id: 74, src: "/mai-images/Mai74.webp", unlocked: false, cost: 1490 },
  { id: 75, src: "/mai-images/Mai75.webp", unlocked: false, cost: 1510 },
  { id: 76, src: "/mai-images/Mai76.webp", unlocked: false, cost: 1530 },
  { id: 77, src: "/mai-images/Mai77.webp", unlocked: false, cost: 1550 },
  { id: 78, src: "/mai-images/Mai78.webp", unlocked: false, cost: 1570 },
  { id: 79, src: "/mai-images/Mai79.webp", unlocked: false, cost: 1590 },
  { id: 80, src: "/mai-images/Mai80.webp", unlocked: false, cost: 1610 },
  { id: 81, src: "/mai-images/Mai81.webp", unlocked: false, cost: 1630 },
  { id: 82, src: "/mai-images/Mai82.webp", unlocked: false, cost: 1650 },
  { id: 83, src: "/mai-images/Mai83.webp", unlocked: false, cost: 1670 },
  { id: 84, src: "/mai-images/Mai84.webp", unlocked: false, cost: 1690 },
  { id: 85, src: "/mai-images/Mai85.webp", unlocked: false, cost: 1710 },
  { id: 86, src: "/mai-images/Mai86.webp", unlocked: false, cost: 1730 },
  { id: 87, src: "/mai-images/Mai87.webp", unlocked: false, cost: 1750 },
  { id: 88, src: "/mai-images/Mai88.webp", unlocked: false, cost: 1770 },
  { id: 89, src: "/mai-images/Mai89.webp", unlocked: false, cost: 1790 },
  { id: 90, src: "/mai-images/Mai90.webp", unlocked: false, cost: 1810 },
  { id: 91, src: "/mai-images/Mai91.webp", unlocked: false, cost: 1830 },
  { id: 92, src: "/mai-images/Mai92.webp", unlocked: false, cost: 1850 },
  { id: 93, src: "/mai-images/Mai93.webp", unlocked: false, cost: 1870 },
  { id: 94, src: "/mai-images/Mai94.webp", unlocked: false, cost: 1890 },
  { id: 95, src: "/mai-images/Mai95.webp", unlocked: false, cost: 1910 },
  { id: 96, src: "/mai-images/Mai96.webp", unlocked: false, cost: 1930 },
  { id: 97, src: "/mai-images/Mai97.webp", unlocked: false, cost: 1950 },
  { id: 98, src: "/mai-images/Mai98.webp", unlocked: false, cost: 1970 },
  { id: 99, src: "/mai-images/Mai99.webp", unlocked: false, cost: 1990 },
  { id: 100, src: "/mai-images/Mai100.webp", unlocked: false, cost: 2010 },
  { id: 101, src: "/mai-images/Mai101.webp", unlocked: false, cost: 2030 },
  { id: 102, src: "/mai-images/Mai102.webp", unlocked: false, cost: 2050 },
  { id: 103, src: "/mai-images/Mai103.webp", unlocked: false, cost: 2070 },
  { id: 104, src: "/mai-images/Mai104.png", unlocked: false, cost: 2090 },
  { id: 105, src: "/mai-images/Mai105.png", unlocked: false, cost: 2090 },
  { id: 106, src: "/mai-images/Mai106.png", unlocked: false, cost: 2090 },
  { id: 107, src: "/mai-images/Mai107.png", unlocked: false, cost: 2090 },
];