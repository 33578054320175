import React from "react";
import Dashboard from "./components/Dashboard";
import "./index.css"; // Include Tailwind styles

const App = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      <Dashboard />
    </div>
  );
};

export default App;
