import React, { useState } from "react";

const MusicPlayer = () => {
  const [mode, setMode] = useState(""); // "local" or "youtube"
  const [localVideos, setLocalVideos] = useState([]);
  const [currentLocalVideoIndex, setCurrentLocalVideoIndex] = useState(0);
  const [youtubeSearch, setYoutubeSearch] = useState("");
  const [youtubeResults, setYoutubeResults] = useState([]);
  const [youtubeUrl, setYoutubeUrl] = useState("");

  // Handle folder pick
  const handleFolderPick = async () => {
    if (window.showDirectoryPicker) {
      // For browsers that support showDirectoryPicker
      try {
        const dirHandle = await window.showDirectoryPicker();
        const videos = [];

        for await (const entry of dirHandle.values()) {
          if (entry.kind === "file" && entry.name.match(/\.(mp4|webm|mkv|mov)$/i)) {
            const file = await entry.getFile();
            videos.push({ name: entry.name, url: URL.createObjectURL(file) });
          }
        }

        setLocalVideos(videos);
        setCurrentLocalVideoIndex(0);
      } catch (error) {
        console.error("Error accessing folder:", error);
      }
    } else {
      alert("Folder picking is not supported in this browser. Please use the file input option.");
    }
  };

  // Fallback folder picker using file input
  const handleFallbackFolderPick = (event) => {
    const files = event.target.files;
    const videos = [];

    for (const file of files) {
      if (file.type.match(/^video\//)) {
        videos.push({ name: file.name, url: URL.createObjectURL(file) });
      }
    }

    setLocalVideos(videos);
    setCurrentLocalVideoIndex(0);
  };

  // Media controls
  const handlePlayPause = () => {
    const video = document.querySelector("video");
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  const playNextLocalVideo = () => {
    setCurrentLocalVideoIndex((prevIndex) =>
      (prevIndex + 1) % localVideos.length
    );
  };

  const playPreviousLocalVideo = () => {
    setCurrentLocalVideoIndex((prevIndex) =>
      (prevIndex - 1 + localVideos.length) % localVideos.length
    );
  };

  // Fetch YouTube search results
  const handleYoutubeSearch = async () => {
    const searchQuery = youtubeSearch.trim();
    if (!searchQuery) return;

    try {
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/search?part=snippet&type=video&maxResults=10&q=${encodeURIComponent(
          searchQuery
        )}&key=AIzaSyBq1e4n2shcwJSQAw4X5ii2Y5r5ZmSqCuo`
      );
      const data = await response.json();
      setYoutubeResults(data.items);
    } catch (error) {
      console.error("Error fetching YouTube search results:", error);
    }
  };

  const playYoutubeVideo = (videoId) => {
    setYoutubeUrl(`https://www.youtube.com/embed/${videoId}?rel=0&autoplay=1`);
    setYoutubeResults([]); // Clear search results
  };

  // Handle "Enter" key in YouTube search
  const handleYoutubeSearchKeyDown = (event) => {
    if (event.key === "Enter") {
      handleYoutubeSearch();
    }
  };

  // Reset mode
  const resetMode = () => {
    setMode("");
    setLocalVideos([]);
    setYoutubeUrl("");
    setYoutubeResults([]);
  };

  return (
    <div className="p-4 rounded-lg shadow-lg bg-gradient-to-b from-gray-800 to-gray-700 text-white">
      <h2 className="text-2xl font-bold mb-4">Miku's Playlist</h2>

      {/* Initial Options */}
      {mode === "" && (
        <div className="flex gap-4">
          <button
            className="px-4 py-2 bg-miku-pink text-white rounded hover:bg-pink-600"
            onClick={() => setMode("local")}
          >
            Local Videos
          </button>
          <button
            className="px-4 py-2 bg-[#00e5ff] text-white rounded hover:bg-[#00e5ff]"
            onClick={() => setMode("youtube")}
          >
            YouTube
          </button>
        </div>
      )}

      {/* Back Button */}
      {mode !== "" && (
        <button
          className="mt-4 px-2 py-1 bg-gray-600 text-white rounded hover:bg-gray-500"
          onClick={resetMode}
        >
          Back
        </button>
      )}

      {/* Local Video Playlist */}
      {mode === "local" && (
        <div className="mt-4">
          {/* Folder Picker */}
          <div className="mb-4">
            <button
              className="px-4 py-2 bg-miku-pink text-white rounded hover:bg-pink-600"
              onClick={handleFolderPick}
            >
              Pick Folder (Modern Browsers)
            </button>
            <label
              htmlFor="fallback-folder-picker"
              className="cursor-pointer ml-4 px-4 py-2 bg-miku-pink text-white rounded hover:bg-pink-600"
            >
              Pick Folder (Fallback)
            </label>
            <input
              id="fallback-folder-picker"
              type="file"
              multiple
              webkitdirectory="true"
              style={{ display: "none" }}
              onChange={handleFallbackFolderPick}
            />
          </div>
          {localVideos.length > 0 && (
            <div>
              <p className="text-lg">Playing: {localVideos[currentLocalVideoIndex]?.name}</p>
              <video
                src={localVideos[currentLocalVideoIndex]?.url}
                controls
                autoPlay
                className="w-full rounded-lg mt-2"
                onEnded={playNextLocalVideo}
              ></video>
              <div className="mt-4 flex gap-4">
                <button
                  className="px-4 py-2 bg-[#00e5ff] text-white rounded hover:bg-miku-aqua"
                  onClick={playPreviousLocalVideo}
                >
                  Previous
                </button>
                <button
                  className="px-4 py-2 bg-[#00e5ff] text-white rounded hover:bg-miku-aqua"
                  onClick={handlePlayPause}
                >
                  Play/Pause
                </button>
                <button
                  className="px-4 py-2 bg-[#00e5ff] text-white rounded hover:bg-miku-aqua"
                  onClick={playNextLocalVideo}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      {/* YouTube Search and Playback */}
      {mode === "youtube" && (
        <div className="mt-4">
          <div className="mb-4 flex items-center gap-2">
            <input
              type="text"
              placeholder="Search YouTube"
              value={youtubeSearch}
              onChange={(e) => setYoutubeSearch(e.target.value)}
              onKeyDown={handleYoutubeSearchKeyDown}
              className="p-2 border rounded flex-grow bg-gray-600 text-white"
            />
            <button
              className="px-4 py-2 bg-miku-aqua text-white rounded hover:bg-[#00e5ff]"
              onClick={handleYoutubeSearch}
            >
              Search
            </button>
          </div>

          {youtubeResults.length > 0 && (
            <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
              {youtubeResults.map((result) => (
                <div
                  key={result.id.videoId}
                  className="p-4 bg-gray-700 rounded-lg hover:bg-gray-600 cursor-pointer"
                  onClick={() => playYoutubeVideo(result.id.videoId)}
                >
                  <img
                    src={result.snippet.thumbnails.medium.url}
                    alt={result.snippet.title}
                    className="rounded-md"
                  />
                  <p className="text-sm mt-2">{result.snippet.title}</p>
                </div>
              ))}
            </div>
          )}

          {youtubeUrl.includes("embed") && (
            <iframe
              src={youtubeUrl}
              className="w-full rounded-lg shadow-lg mt-4"
              height="315"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </div>
      )}
    </div>
  );
};

export default MusicPlayer;
