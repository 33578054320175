import React, { useState, useEffect } from "react";
import Login from "./Login";
import TaskList from "./TaskList";
import Rewards from "./Rewards";
import MusicPlayer from "./MusicPlayer";
import {
  resetRewardsInStorage,
  getPoints,
  savePoints,
  saveTasks,
} from "./localStorageUtils";
import motivationalQuotes from "./motivationalQuotes"; // Ensure you have this file

const RandomMotivationalQuote = () => {
  const [quote, setQuote] = useState("");

  useEffect(() => {
    const randomQuote =
      motivationalQuotes[Math.floor(Math.random() * motivationalQuotes.length)];
    setQuote(randomQuote);
  }, []); // Runs once on component mount

  return (
    <div className="mt-6 mb-8 p-4 rounded-lg bg-gradient-to-r from-pink-500 via-red-400 to-miku-teal shadow-lg">
      <p className="text-2xl font-semibold text-center text-white italic">
        {quote}
      </p>
    </div>
  );
};

const Dashboard = () => {
  const [authToken, setAuthToken] = useState(localStorage.getItem("authToken"));
  const [points, setPoints] = useState(0);

  const handleLogin = (token) => {
    localStorage.setItem("authToken", token);
    setAuthToken(token);
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    setAuthToken(null);
  };

  // Fetch points from the backend on mount
  useEffect(() => {
    const fetchPoints = async () => {
      try {
        const storedPoints = await getPoints();
        setPoints(storedPoints);
      } catch (error) {
        console.error("Failed to fetch points:", error);
      }
    };

    fetchPoints();
  }, []);

  const completeReset = async () => {
    // Reset points
    setPoints(0);
    await savePoints(0);

    // Reset tasks
    await saveTasks([]);

    // Reset rewards
    await resetRewardsInStorage();
  };

  const updatePoints = async (earnedPoints) => {
    const newPoints = points + earnedPoints;
    setPoints(newPoints);
    await savePoints(newPoints);
  };

  const spendPoints = async (cost) => {
    const newPoints = points - cost;
    setPoints(newPoints);
    await savePoints(newPoints);
  };

  if (!authToken) {
    return <Login onLogin={handleLogin} />;
  }

  return (
    <div className="p-8 bg-gray-900 text-white min-h-screen">
      <div className="flex justify-between items-center">
        <h1 className="text-4xl font-extrabold text-center text-[#137a7f] mb-2">
          The Mai-Miku Atelier
        </h1>
        <button
          onClick={handleLogout}
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
          Logout
        </button>
      </div>
      {/* Random Motivational Quote */}
      <RandomMotivationalQuote />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* TaskList */}
        <TaskList updatePoints={updatePoints} completeReset={completeReset} />
        {/* MusicPlayer */}
        <MusicPlayer />
      </div>
      {/* Rewards Section */}
      <div className="mt-12">
        <Rewards points={points} spendPoints={spendPoints} />
      </div>
    </div>
  );
};

export default Dashboard;
