import React, { useState, useEffect } from "react";
import { getStoredRewards, saveRewardsToStorage } from "./localStorageUtils";
import { defaultRewards } from "./rewardsConfig";

const Rewards = ({ points, spendPoints }) => {
  const [rewards, setRewards] = useState([]);
  const [modalImage, setModalImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const rewardsPerPage = 12; // Number of rewards to display per page

  // Calculate the total number of pages
  const totalPages = Math.ceil(rewards.length / rewardsPerPage);

  // Fetch rewards from backend on mount
  useEffect(() => {
    const fetchRewards = async () => {
      try {
        let storedRewards = await getStoredRewards();
        if (!storedRewards || storedRewards.length === 0) {
          // Initialize rewards in backend with defaultRewards
          storedRewards = defaultRewards;
          await saveRewardsToStorage(defaultRewards);
        }
        setRewards(storedRewards);
      } catch (error) {
        console.error("Failed to fetch rewards:", error);
        setRewards(defaultRewards);
      }
    };

    fetchRewards();
  }, []);

  const unlockImage = async (id, cost) => {
    if (points < cost) {
      alert("Not enough points to unlock this image.");
      return;
    }
    const updatedRewards = rewards.map((reward) =>
      reward.id === id ? { ...reward, unlocked: true } : reward
    );
    setRewards(updatedRewards);
    await saveRewardsToStorage(updatedRewards);
    await spendPoints(cost);
  };

  const openModal = (imageSrc) => {
    setModalImage(imageSrc);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  // Handle pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  // Determine the rewards to display on the current page
  const startIndex = (currentPage - 1) * rewardsPerPage;
  const currentRewards = rewards.slice(startIndex, startIndex + rewardsPerPage);

  return (
    <div className="p-4 rounded-lg shadow-xl bg-gradient-to-b from-pink-500 to-pink-400 text-white">
      <h2 className="text-3xl font-bold mb-6">Mai's Rewards</h2>
      <p className="mb-6 text-xl">Points: {points}</p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {currentRewards.map((reward) => (
          <div
            key={reward.id}
            className={`transition-all duration-300 transform hover:scale-105 text-center p-6 rounded-lg shadow-lg ${
              reward.unlocked
                ? "bg-white text-miku-teal"
                : "bg-gray-800 text-gray-400"
            }`}
          >
            {reward.unlocked ? (
              <img
                src={reward.src}
                alt={`Mai ${reward.id}`}
                className="w-full h-32 object-cover rounded-lg shadow-md cursor-pointer"
                onClick={() => openModal(reward.src)} // Open modal on click
              />
            ) : (
              <>
                <p className="text-lg font-medium">
                  Cost: {reward.cost} points
                </p>
                <button
                  className="mt-4 px-4 py-2 bg-[#00e5ff] text-white rounded-lg hover:bg-miku-teal"
                  onClick={() => unlockImage(reward.id, reward.cost)}
                >
                  Unlock
                </button>
              </>
            )}
          </div>
        ))}
      </div>

      {/* Pagination controls */}
      <div className="flex justify-center mt-6">
        <button
          className={`px-4 py-2 mx-2 rounded-lg ${
            currentPage === 1
              ? "bg-gray-600 cursor-not-allowed"
              : "bg-white text-miku-teal hover:bg-gray-200"
          }`}
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="px-4 py-2 bg-gray-100 text-miku-teal rounded-lg">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className={`px-4 py-2 mx-2 rounded-lg ${
            currentPage === totalPages
              ? "bg-gray-600 cursor-not-allowed"
              : "bg-white text-miku-teal hover:bg-gray-200"
          }`}
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      {/* Modal for full-size image */}
      {modalImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative">
            <img
              src={modalImage}
              alt="Full size"
              className="max-w-full max-h-full rounded-lg"
            />
            <button
              className="absolute top-2 right-2 text-white text-2xl bg-red-500 rounded-full px-2 py-1"
              onClick={closeModal}
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Rewards;
