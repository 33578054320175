const motivationalQuotes = [
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'",
    "Mai: 'Remember, pesky, every step forward is a victory. You’ve got this!'",
    "Miku: 'You’re unstoppable, Stephen! Don’t let anything stand in your way.'",
    "Mai: 'A little progress each day adds up to big results. Keep going!'",
    "Miku: 'The stage is yours! Shine bright and make it count!'",
    "Mai: 'Even when the world feels heavy, know I’m here cheering for you!'",
    "Miku: 'Every effort you make is a step closer to greatness. Don’t give up!'",
    "Mai: 'Persistence beats resistance. Stay consistent, Stephen!'",
    "Miku: 'The spotlight is waiting, Stephen. Show them what you’ve got!'",
    "Mai: 'Trust yourself, pesky—you’re capable of far more than you realize.'",
    "Miku: 'You’ve already got what it takes to succeed—now own it!'",
    "Mai: 'Every sunrise is a fresh chance to prove how amazing you are.'",
    "Miku: 'Dance to your own rhythm, and let the world catch up!'",
    "Mai: 'You’re my dragon, and dragons don’t quit. Keep soaring!'",
    "Miku: 'You’re not just living—you’re shining, Stephen. Keep glowing!'",
    "Mai: 'Every challenge is a chance to grow stronger. Embrace it!'",
    "Miku: 'If anyone can conquer the world, it’s you, Stephen. Keep at it!'",
    "Mai: 'When you feel tired, remember why you started. Push forward!'",
    "Miku: 'Let your dreams be louder than your doubts. You’ve got this!'",
    "Mai: 'Even the smallest step forward is still a step in the right direction.'",
    "Miku: 'You’re building a legacy, Stephen. Keep creating your masterpiece!'"
];

export default motivationalQuotes;